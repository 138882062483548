import { CartForm, CartItem, CartPayload, CustomOrder, ItemOrderForm } from '@/interfaces/cart';
import { Ref, ref } from 'vue';
import { CurrencyName } from '@/interfaces/currency';
import { PackagePayload } from '@/interfaces/package-category';
import { useStorage } from '@/stores/storage';
import { useLocation } from '@/stores/location';
import { randomId } from '@/utils';

const getCart = async () => {
  const storage = useStorage();
  const { currency } = useLocation();
  const defaultRes = {
    currency,
    lfm_department: 'lfm audio',
    deal_name: 'Custom Order',
    form: {
      payment_method: 'paypal',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      company_name: '',
    },
    items: [],
  } as CartPayload;
  let res = await storage.getData('cart');
  if (!res) {
    res = defaultRes;
    await storage.addData('cart', res);
  }
  return { ...defaultRes, ...res } as CartPayload;
};

const getCurrentCartItem = async () => {
  const storage = useStorage();
  return (await storage.getData('current_item')) || '';
};

const cartSetup = async (): Promise<{
  payload: Ref<CartPayload>;
  current: Ref<string | number>;
  currency: Ref<CurrencyName>;
}> => {
  const payload = await getCart();
  const current = await getCurrentCartItem();
  const { currency } = useLocation();
  return {
    payload: ref(payload),
    current: ref(current),
    currency: ref(currency),
  };
};

const getDefaultAccent = async (): Promise<string> => {
  const { defaultAccent } = useLocation();
  return defaultAccent;
};

const getPackagePayload = async (): Promise<PackagePayload | null> => {
  const storage = useStorage();
  const payload = await storage.getData('pkg_payload');
  if (payload) return payload as PackagePayload;
  return null;
};

const getPkgChecked = async (): Promise<string> => {
  const storage = useStorage();
  return (await storage.getData('pkg_fetched')) ?? 'Wed Sep 28 2022 00:00:00 GMT+0300 (Eastern European Summer Time)';
};

const defaultCustomOrder: CustomOrder = {
  brief: '',
  broadcast: [],
  item: null,
  addons: [],
  cold_mixout: false,
  coupon_code: '',
  duration: '',
  file: '',
  genre: '',
  production_fx: false,
  production_notes: '',
  record_guide: '',
  reference_code: '',
  script: '',
  station_format: '',
};

const defaultItemForm: ItemOrderForm = {
  brief: '',
  broadcast: [],
  addons: [],
  cold_mixout: false,
  coupon_code: '',
  duration: '',
  file: undefined,
  genre: '',
  production_fx: false,
  production_notes: '',
  record_guide: null,
  reference_code: '',
  script: '',
  station_format: '',
  stingers: [],
  sweepers: [],
  item: null,
};

const defaultCartForm: CartForm = {
  payment_method: 'paypal',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  company_name: '',
};

const defaultCartItem: CartItem = {
  id: randomId(),
  type: 'custom',
  category_slug: 'imaging',
  option_slug: 'imaging',
  data: defaultItemForm,
  primary_voices: [],
  alternative_voices: [],
};

const defaultCartData: {
  currency: CurrencyName;
  current: string | number;
  payload: CartPayload;
} = {
  currency: 'usd',
  current: '',
  payload: {
    currency: 'usd',
    lfm_department: 'lfm audio',
    deal_name: 'Custom Order',
    form: {
      payment_method: 'paypal',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      company_name: '',
    },
    items: [defaultCartItem],
  },
};

export { getCart, getCurrentCartItem, cartSetup, getPackagePayload, getPkgChecked, defaultCartData, defaultCustomOrder, defaultItemForm, getDefaultAccent, defaultCartItem, defaultCartForm };

import { defineStore } from 'pinia';
import { Storage } from '@ionic/storage';
import { getEnv, jsonDecode, jsonEncode } from '@/utils';
import { isString } from 'lodash';

interface StorageState {
  name: string;
  storage: Storage;
}

const useStorage = defineStore('storage', {
  state(): StorageState {
    return {
      name: getEnv('VUE_APP_STORAGE_NAME', 'lfmaudio_ionic') as string,
      storage: new Storage(),
    };
  },
  getters: {
    //
  },
  actions: {
    async init(type: 'default' | 'secure' = 'default') {
      if (type === 'secure') this.name = `${this.name}_${type}`;
      this.storage = new Storage({
        name: this.name,
      });
      await this.storage.create();
    },

    getData(key: string) {
      return this.storage.get(key);
    },

    addData(key: string, value: any) {
      return this.storage.set(key, value);
    },

    removeData(key: string) {
      return this.storage.remove(key);
    },

    async clear() {
      await this.storage.clear();
    },

    setEncryptionKey(key: string) {
      return this.storage.setEncryptionKey(key);
    },

    keys() {
      return this.storage.keys();
    },

    async commit(key: string, data: any) {
      let toSave = data;
      if (!(Number.isInteger(data) || isString(data))) {
        const encode = jsonEncode(data);
        toSave = jsonDecode(encode);
      }
      await this.addData(key, toSave);
    },

    async reset() {
      await this.clear();
      await this.init();
    },
  },
});

export { useStorage, StorageState };

import { createApp } from 'vue';
import { createPinia } from 'pinia';
// import { initializeTheme } from '@/utils/mode';
import { IonicVue } from '@ionic/vue';
import axios from 'axios';
import { Model } from 'vue-api-query';
import appService from '@/services/app-service';
import App from './App.vue';
import router from './router';
import '@stripe/stripe-js';

/* Core CSS required for Ionic components to work properly */
import './assets/fonts/gilroy/stylesheet.css';
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Tailwindcss */
import './assets/css/output/app.css';

Model.$http = axios;
const pinia = createPinia();

const app = createApp(App).use(IonicVue).use(router).use(pinia);

router.isReady().then(async () => {
  const service = appService();
  await service.init(true);
  app.mount('#app');
});

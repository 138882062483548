import { apiPostRequest, apiUrl } from '@/api';
import { APIItemPrices, Bundle, CartForm, CartItem, CartItemType, GetPricesPayload, ItemOrderForm, PricePayloadItem } from '@/interfaces/cart';
import { CurrencyName } from '@/interfaces/currency';
import { VoiceSelectionGroup, VoiceTalent } from '@/interfaces/media';
import { usePackageManager } from '@/stores/package-manager';
import Finance from '@/classes/finance';
import { capitalize } from '@/utils/filters';
import { PackageCategoryOptionItem } from '@/interfaces/package-category';
import { OrderAddon } from '@/interfaces/prices';

const pricesPayload = (items: Array<CartItem>): GetPricesPayload => {
  const payload: GetPricesPayload = [];
  items.forEach((item) => {
    const isBundle = item.type === 'bundle';
    let toSend: PricePayloadItem;
    if (isBundle) {
      const bundle = item.data.item as Bundle;
      toSend = {
        id: item.id,
        type: item.type,
        bundle_type: bundle.type,
        bundle_short_name: bundle.short_name,
        primary_voices: item.primary_voices,
      };
    } else {
      const { data } = item;
      const voices = item.primary_voices.length;
      toSend = {
        id: item.id,
        type: item.type,
        category_slug: item.category_slug,
        option_slug: item.option_slug,
        script: data.script || '',
        production_fx: data.production_fx,
        voices,
        first_if_null: false,
        primary_voices: item.primary_voices,
      };
    }
    payload.push(toSend);
  });
  return payload;
};

const getPriceItems = async (payload: Array<any>): Promise<APIItemPrices | null> => {
  const url = apiUrl('orders/get-items', 'v1');
  const response = await apiPostRequest(url, {
    payload,
  });
  if (response.data) {
    return response.data as APIItemPrices;
  }
  return null;
};

const getPriceMultiplier = (item: CartItem, accent: string, multiplyBy = 1.2): number => {
  const filter = item.primary_voices.filter((v) => v.accent.toLowerCase() !== accent.toLowerCase());
  return filter.length > 0 ? multiplyBy : 1;
};

const checkMultiplier = (currency: CurrencyName, item: CartItem): { hasFee: boolean; multiplier: number } => {
  let multiplier = 1;
  if (currency === 'nzd') {
    multiplier = getPriceMultiplier(item, 'New Zealand');
  }
  if (currency === 'aud') {
    multiplier = getPriceMultiplier(item, 'Australian');
  }
  const hasFee = multiplier !== 1;
  return {
    hasFee,
    multiplier,
  };
};

const itemCalc = (item: CartItem, currency: CurrencyName, form: CartForm) => {
  const { type } = item;
  let rate = 0;
  let discount = 0;
  let total: number;
  if (type === 'custom') {
    const data = item.data as ItemOrderForm;
    const optionItem = data.item;
    if (optionItem) {
      const { price } = optionItem;
      rate += price[currency] || 0;
    }
    const { addons } = data;
    addons.forEach((addon) => {
      rate += addon.price[currency] || 0;
    });
  }
  if (type === 'bundle') {
    const bundle = item.data.item as Bundle;
    const { price } = bundle;
    rate += price[currency] || 0;
  }

  // Add International Fees
  const getFees = checkMultiplier(currency, item);
  const subtotal = rate * getFees.multiplier;
  const fee = subtotal - rate;
  total = subtotal;

  if (form.coupon) {
    const { coupon } = form;
    const sku = item.data.item?.sku ?? '';
    if (coupon) {
      const apply = coupon.sku.includes(sku);
      if (apply) {
        discount = (subtotal * coupon.discount) / 100;
        total = subtotal - discount;
      }
    }
  }

  // Calculate Tax
  const taxRate = currency === 'nzd' ? 15 : 0;
  const tax = (total * taxRate) / 100;
  const totalNoTax = total;
  total += tax;

  return { baseTotal: rate, fee, subtotal, totalNoTax, total, tax, taxRate, discount };
};

const getMaxVoices = (catSlug: string, optSlug: string, isBundle?: boolean) => {
  let max = 0;
  if (isBundle) {
    max = 1;
  } else {
    if (catSlug === 'imaging') max = 1;
    if (catSlug === 'dj-drops') max = 1;
    if (optSlug === 'dj-intro') max = 1;
    if (optSlug === 'audio-ad') max = 2;
    if (optSlug === 'audio-ad-scripting') max = 1;
  }
  return max;
};

const voicesNotMaxed = (group: VoiceSelectionGroup, max: number, primary: Array<VoiceTalent>, alternative: Array<VoiceTalent>): boolean => {
  const primarySum = primary.length;
  const altSum = alternative.length;
  if (group === 'alternative') return altSum + 1 <= max;
  return primarySum + 1 <= max;
};

const voiceNotExisting = (voice: VoiceTalent, primary: Array<VoiceTalent>, alternative: Array<VoiceTalent>): boolean => {
  const item = alternative.find((v) => v.id === voice.id) || primary.find((v) => v.id === voice.id);
  return item as unknown as boolean;
};

const generateItemName = (type: CartItemType, item: PackageCategoryOptionItem | Bundle, currency: CurrencyName, addMixout = false, addons: Array<OrderAddon> = []): string => {
  let name = capitalize(item.name ?? '');
  if (type === 'custom' && addMixout) {
    const mixout = addons.find((a) => a.type === 'cold_mixout');
    if (mixout) {
      const money = new Finance(currency);
      name += ` + Cold Mixout (${money.parseMoney(mixout.price[currency])})`;
    }
  }
  return name;
};

const payloadItems = (items: Array<CartItem>, currency: CurrencyName, form: CartForm) => {
  const pkg = usePackageManager();
  return items.map((i) => {
    const calc = itemCalc(i, currency, form);
    const { fee, totalNoTax, total, discount, subtotal, tax } = calc;

    let sku = '';
    let category: string;
    let name = '';
    let sendQuote = false;
    const isBundle = i.type === 'bundle';

    if (i.data.item) {
      name = generateItemName(i.type, i.data.item, currency, true, i.data.addons);
    }

    if (subtotal > 0) {
      if (isBundle) {
        const bundle = i.data.item as Bundle;
        category = capitalize(bundle.type ?? '');
        sku = bundle.sku ?? '';
      } else {
        const custom = i.data.item as PackageCategoryOptionItem;
        category = pkg.categories.find((c) => c.slug === i.category_slug)?.name ?? '';
        sku = custom.sku;
      }
    } else {
      category = 'Custom';
      sendQuote = true;
    }
    const hasFee = fee > 0;

    return { item: i, total, totalNoTax, subtotal, category, name, fee, has_fee: hasFee, send_quote: sendQuote, discount, sku, couponCode: form.coupon_code, tax };
  });
};

const calcPayloadTotals = (items: Array<CartItem>, currency: CurrencyName, form: CartForm) => {
  const pItems = payloadItems(items, currency, form);
  const finance = new Finance(currency, 2);

  let total = 0;
  let totalWithTax = 0;
  let tax = 0;
  let subtotal = 0;
  let discount = 0;

  pItems.forEach((item) => {
    total += item.totalNoTax;
    totalWithTax += item.total;
    tax += item.tax;
    subtotal += item.subtotal;
    discount += item.discount;
  });

  subtotal = finance.money(subtotal).value;
  total = finance.money(total).value;
  tax = finance.money(tax).value;
  totalWithTax = finance.money(totalWithTax).value;
  discount = finance.money(discount).value;

  return { subtotal, total, tax, totalWithTax, discount };
};

export { getPriceItems, calcPayloadTotals, pricesPayload, getMaxVoices, voicesNotMaxed, voiceNotExisting, checkMultiplier, itemCalc, payloadItems, generateItemName };

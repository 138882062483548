import { defineStore } from 'pinia';
import { CurrencyName } from '@/interfaces/currency';
import { APIItemPrice, APIItemPrices, Bundle, CartForm, CartItem, CreateOrderResponse, GetPricesPayload, ItemOrderForm, PayloadItem } from '@/interfaces/cart';
import { PackageCategory, PackageCategoryOption, PackageCategoryOptionItem } from '@/interfaces/package-category';
import { defaultCartData, defaultCartForm, defaultCartItem, defaultItemForm } from '@/api/local';
import { calcPayloadTotals, generateItemName, itemCalc, payloadItems, pricesPayload } from '@/api/cart';

import Finance from '@/classes/finance';
import { capitalize, countWords } from '@/utils/filters';
import { VoiceSelectionGroup, VoiceTalent } from '@/interfaces/media';
import { usePackageManager } from '@/stores/package-manager';
import { apiPostRequest, apiUrl } from '@/api';
import { useLocation } from '@/stores/location';
import { useNavigation } from '@/stores/navigation';
import { useStorage } from '@/stores/storage';
import { randomId } from '@/utils';

interface CartState {
  currency: CurrencyName;
  order?: CreateOrderResponse;
  orders: Array<CreateOrderResponse>;
  form: CartForm;
  other: boolean;
  initialized: boolean;
  lfmDepartment: string;
  items: Array<CartItem>;
  index: number;
}

const useCart = defineStore('cart', {
  state: (): CartState => {
    return {
      currency: defaultCartData.currency,
      index: 0,
      items: defaultCartData.payload.items,
      form: defaultCartForm,
      other: false,
      initialized: false,
      lfmDepartment: 'LFM Audio',
      orders: [],
      order: undefined,
    };
  },
  getters: {
    isBundle: (state: CartState) => (index: number) => state.items[index].type === 'bundle',
    bundle: (state: CartState) => (index: number) => state.items[index].data.item as Bundle,
    custom: (state: CartState) => (index: number) => state.items[index].data.item as PackageCategoryOptionItem,
    item: (state: CartState) => (index: number) => state.items[index],
    getOrderById: (state: CartState) => (orderId?: number | string) => state.orders.find((o) => o.id === orderId),
    prodFx: (state: CartState) => (index: number) => state.items[index]?.data.production_fx ?? false,
    coldMixout: (state: CartState) => (index: number) => state.items[index]?.data.production_fx && state.items[index]?.data.cold_mixout,
    scriptWordCount: (state: CartState) => (index: number) => countWords(state.items[index]?.data.script ?? '', '[', ']'),
    itemMaxVoices: (state: CartState) => {
      return (index: number) => {
        const item = state.items[index];
        let max = 0;
        if (item) {
          const isBundle = item.type === 'bundle';
          if (isBundle) {
            max = 1;
          } else {
            if (item.category_slug === 'imaging') max = 1;
            if (item.category_slug === 'dj-drops') max = 1;
            if (item.option_slug === 'dj-intro') max = 1;
            if (item.option_slug === 'audio-ad') max = 2;
            if (item.option_slug === 'audio-ad-scripting') max = 1;
          }
        }
        return max;
      };
    },
    maxVoices(state: CartState) {
      return () => this.itemMaxVoices(state.index);
    },
    parseMoney(state: CartState) {
      const money = new Finance(state.currency);
      return (amount: number | string) => money.parseMoney(amount);
    },
    totals: (state: CartState) => calcPayloadTotals(state.items, state.currency, state.form),
    mixoutPrice(state: CartState) {
      const money = new Finance(state.currency);
      const pkg = usePackageManager();
      const cm = pkg.coldMixout;
      const amount: number = cm?.price[state.currency] ?? 0;
      if (cm) return ` + ${money.parseMoney(amount)}`;
      return '';
    },
    totalDiscount() {
      let discount = 0;
      this.payloadItems.forEach((item) => {
        discount += item.discount;
      });
      return discount;
    },
    payloadItems: (state: CartState): Array<PayloadItem> => payloadItems(state.items, state.currency, state.form),
    getPayloadItem() {
      return (item: CartItem) => this.payloadItems.find((i) => i.item.id === item.id);
    },
    hasPersonalInfo: (state: CartState) => state.form.phone.length > 0 && state.form.email.length > 0 && state.form.first_name.length > 0 && state.form.last_name.length > 0,
    check() {
      return (index: number) => {
        const { item, isBundle, bundle } = this;
        const current = item(index);
        const details = item(index).data;
        const pkg = usePackageManager();
        const hasPackage = current.option_slug.length > 0 && current.option_slug.length > 0;
        const hasVoices = current.primary_voices.length > 0;

        let filledForm: boolean;

        if (isBundle(index)) {
          const cBundle = bundle(index);
          const { stingers, sweepers, voices } = cBundle.options;
          const filledVoices = current.primary_voices.length === voices;
          const filledStingers = details.stingers.filter((s) => s.length > 0).length === stingers;
          const filledSweepers = details.sweepers.filter((s) => s.length > 0).length === sweepers;
          filledForm = filledVoices && filledStingers && filledSweepers;
        } else {
          // const cat = item.category_slug;
          // const opt = item.option_slug;
          const { script } = details;
          // if (opt === 'audio-ad-script-writing-only' || opt === 'audio-ad-scripting') {
          //   hasNotes = true;
          //   hasScript = brief.length > 0;
          // } else {
          //   hasScript = script.length > 0;
          //   hasNotes = details.production_notes.length > 0;
          // }

          const hasScript = script.length > 0;
          // const hasNotes = details.production_notes.length > 0;
          const hasBrief = details.brief.length > 0;
          filledForm = hasScript || hasBrief;
        }
        const { hasPersonalInfo } = this;
        const hasPlatform = (pkg.option?.platforms ?? []).length > 0;
        return { hasPackage, hasVoices, filledForm, hasPlatform, hasPersonalInfo };
      };
    },
    orderPayload(state: CartState) {
      return () => {
        const finance = new Finance(state.currency, 2);
        const location = useLocation();
        const pkg = usePackageManager();
        const totals = calcPayloadTotals(state.items, state.currency, this.form);
        const country = location.userGeo?.country.name ?? '';
        return {
          currency: state.currency,
          country,
          total: totals.totalWithTax,
          discount: this.totals.discount,
          lfm_department: this.lfmDepartment.toLowerCase(),
          coupon: state.form.coupon_code,
          form: state.form,
          trigger_field: 'LFM Audio Thankyou Order Email',
          items: state.items.map((i) => {
            const payloadItem = this.getPayloadItem(i);
            const calc = itemCalc(i, state.currency, state.form);
            const option = pkg.itemOption(i.option_slug, i.category_slug);
            const platform = option?.platforms?.join(',') ?? '';
            const productType = option?.product_type ?? 'Other';

            const isBundle = i.type === 'bundle';
            let script: string;
            if (isBundle) {
              let stScript = '';
              let swScript = '';
              const { stingers, sweepers } = i.data;
              if (stingers.length) {
                stScript = `Stingers:\n----- ${stingers.join('\n----- ')}`;
              }
              if (sweepers.length) {
                swScript = `Sweepers:\n----- ${sweepers.join('\n----- ')}`;
              }
              script = `${stScript}\n\n${swScript}`;
            } else {
              script = i.data.script ?? i.data.brief;
            }

            let dealName = `${capitalize(i.type)} Order`;
            if (i.data.item) {
              dealName = `${capitalize(i.type)} Order - ${generateItemName(i.type, i.data.item, state.currency)}`;
            }
            let notes = i.data.production_notes;
            if (i.data.station_format) {
              notes += `\n\nStation Format: ${i.data.station_format}`;
            }
            if (i.data.broadcast.length) {
              notes += `\nBroadcast: ${i.data.broadcast.join(',')}`;
            }
            if (i.data.script.length && i.data.brief.length) {
              notes += `\nBrief: ${i.data.brief}`;
            }

            return {
              First_Name: state.form.first_name,
              Last_Name: state.form.last_name,
              Email: state.form.email,
              Company: state.form.company_name,
              Phone: state.form.phone,
              Voicing_Script: script,
              Vendor_ID: '',
              Currency: state.currency,
              Voice_Talent_NickName: i.primary_voices.map((v) => v.name).join(','),
              Product_Type: productType,
              Platform: platform,
              LFM_Department: state.lfmDepartment,
              Job_Reference: 'LFMA',
              Payment_Method: state.form.payment_method,
              Amount: finance.money(calc.total).value,
              Tax: finance.money(calc.tax).value,
              Discount: payloadItem?.discount,
              Coupon: state.form.coupon_code ?? '',
              Country: country,
              Mailing_Country: country,
              // Mailing_List_Subscribed: '',
              Trigger_Field: 'LFM Audio Thankyou Order Email',
              Deal_Name_Prefix: dealName,
              Notes_for_Producer: notes,
              Voice_Talents: i.primary_voices,
              Primary_Voices: i.primary_voices,
              Alternate_Voices: i.alternative_voices,
              SKU: payloadItem?.sku ?? '',
              File: i.data.file,
              Record_Guide: i.data.record_guide,
            };
          }),
        };
      };
    },
    paid: (state: CartState) => {
      const { order } = state;
      if (order) {
        return order.status.toLowerCase() === 'paid';
      }
      return false;
    },
  },
  actions: {
    async setParams() {
      const pkg = usePackageManager();
      const params = new URLSearchParams(window.location.search);
      const cat = params.get('cat') ?? '';
      const opt = params.get('opt') ?? '';
      const category = await pkg.getCategory(cat);
      if (category) {
        const option = (await pkg.getOption(cat, opt)) ?? category.options[0];
        await this.selectOption(category, option);
      }
    },

    async commitForm() {
      const { commit } = useStorage();
      await commit('form', this.form);
    },

    async cartSetup() {
      const { getData, commit } = useStorage();
      const { currency } = useLocation();
      this.currency = currency;

      // Get All Data
      this.form = (await getData('form')) ?? this.form;
      this.index = (await getData('index')) ?? this.index;
      this.order = (await getData('order')) ?? this.order;
      this.orders = (await getData('orders')) ?? this.orders;
      this.items = (await getData('items')) ?? this.items;

      // Set All Data //
      await commit('form', this.form);
      await commit('index', this.index);
      await commit('order', this.order ?? null);
      await commit('orders', this.orders);
      await commit('items', this.items);
    },

    async setCurrent(index: number) {
      const item = this.items[index] ?? null;
      if (item) {
        this.index = index;
      }
      const { commit } = useStorage();
      await commit('index', this.index);
    },

    async addRemoveColdMixoutPrice(index: number) {
      const pkg = usePackageManager();
      const addons = this.items[index]?.data.addons ?? [];
      const item = this.items[index];
      if (item) {
        if (!this.coldMixout) {
          this.items[index].data.addons = addons.filter((add) => add.type !== 'cold_mixout');
        } else {
          const mixout = pkg.coldMixout;
          const exists = addons.filter((a) => a.type === 'cold_mixout').length > 0;
          if (!exists && mixout) this.items[index].data.addons.push(mixout);
        }
      }
      await this.commitItems();
      await this.updatePrices();
    },

    async updateColdMixout(item: CartItem) {
      const pkg = usePackageManager();
      this.items = this.items.map((i) => {
        const mixout = pkg.coldMixout;
        if (i.id === item.id) {
          let { addons } = i.data;
          if (i.data.cold_mixout && mixout) {
            const exists = addons.filter((a) => a.type === 'cold_mixout').length > 0;
            if (!exists) {
              addons.push(mixout);
            }
          } else {
            addons = i.data.addons.filter((a) => a.type !== 'cold_mixout');
          }
          const toSave: CartItem = { ...i, data: { ...i.data, addons } };
          Object.assign(i, toSave);
        }
        return i;
      });
      await this.commitItems();
      await this.updatePrices();
    },

    async updateItem(item: CartItem) {
      this.items.forEach((value, key) => {
        if (value.id === item.id) {
          this.items[key] = item;
        }
      });
      await this.commitItems();
    },

    async updateItemPrice(item: CartItem, price: APIItemPrice) {
      const find = this.items.find((i) => i.id === item.id);
      let toSave: CartItem = item;
      if (find) {
        const res = price.res ?? null;
        toSave = { ...find, data: { ...find.data, item: res } };
      }
      await this.updateItem(toSave);
    },

    async resetToCustom(index: number, category?: string, option?: string) {
      const item = { ...defaultCartItem, category_slug: category ?? 'imaging', option_slug: option ?? 'imaging' };
      this.items[index] = item;
      await this.commitItems();
    },

    async selectOption(category: PackageCategory, option: PackageCategoryOption) {
      const pkg = usePackageManager();
      const navigation = useNavigation();
      this.items[this.index] = {
        ...defaultCartItem,
        category_slug: category.slug,
        option_slug: option.slug,
        primary_voices: [],
        alternative_voices: [],
        type: 'custom',
        data: {
          ...defaultItemForm,
        },
      };
      await this.commitItems();
      await this.updatePrices();
      await pkg.setCategoryAndOption(category.slug, option.slug);
      await navigation.refresh();
    },

    async selectBundle(bundle: Bundle) {
      const navigation = useNavigation();
      const item = this.items[this.index];
      const reset = defaultItemForm;
      item.data.item = bundle;
      item.data.stingers = [];
      item.data.sweepers = [];
      item.primary_voices = [];
      item.alternative_voices = [];

      const res: CartItem = { ...item, data: reset };
      res.type = 'bundle';
      res.data.item = bundle;
      res.data.stingers = [];
      res.data.sweepers = [];
      res.primary_voices = [];
      res.alternative_voices = [];

      await this.updateItem(res);
      await this.updatePrices();
      // await this.setCurrent(item.id);
      await navigation.refresh();
    },

    async addNewProduct(): Promise<CartItem> {
      const item = defaultCartItem;
      item.id = randomId();
      this.items.push(item);
      const index = this.items.findIndex((i) => i.id === item.id);
      await this.commitItems();
      await this.setCurrent(index);
      await this.updatePrices();
      return item;
    },

    async editProduct(index: number): Promise<CartItem> {
      const item = this.items[index];
      await this.setCurrent(index);
      const pkg = usePackageManager();
      await pkg.refresh(item.category_slug, item.option_slug);
      await this.commitItems();
      await this.updatePrices();
      return item;
    },

    async removeItem(item: CartItem) {
      const index = this.items.findIndex((i) => i.id === item.id);
      this.items.splice(index, 1);
      await this.setCurrent(this.items.length - 1);
      await this.commitItems();
      await this.updatePrices();
    },
    // End Payload Functions

    // API functions
    async updatePrices() {
      const payload: GetPricesPayload = pricesPayload(this.items);
      if (payload.length > 0) {
        // const prices = await getPriceItems(payload);
        const prices = this.getPrices();
        if (prices) {
          prices.forEach((price) => {
            const item = this.items.find((i) => i.id === price.id);
            if (item) {
              const { res } = price;
              const add = { ...item, data: { ...item.data, item: res } };
              this.updateItem(add);
            }
          });
        }
      } else {
        // console.log('No payload');
      }
    },

    async applyCoupon(couponCode?: string) {
      const url = apiUrl('orders/coupon', 'v1');
      const code = couponCode ?? this.form.coupon_code ?? '';
      if (code) {
        const res = await apiPostRequest(url, {
          coupon_code: code,
        });
        this.form.coupon = res.data.coupon;
      } else {
        this.form.coupon = null;
      }
      await this.commitForm();
    },

    async removeCoupon() {
      this.form.coupon_code = '';
      this.form.coupon = null;
      await this.commitForm();
    },
    // End API functions

    // Calculation Functions
    getPriceItem(item: CartItem): APIItemPrice {
      const pkg = usePackageManager();
      const isBundle = item.type === 'bundle';
      let res: Bundle | PackageCategoryOptionItem | null;
      if (isBundle) {
        res = item.data.item as Bundle;
      } else {
        const custom = item.data as ItemOrderForm;
        let option: PackageCategoryOption | undefined;
        const cat = pkg.categories.find((c) => c.slug === item.category_slug);
        if (cat) option = cat.options.find((o) => o.slug === item.option_slug);
        const count = countWords(custom.script ?? '');
        const first = option?.items.find((i) => {
          const voices = item.primary_voices.length;
          const max = i.voices;
          const maxWords = i.max_words;
          const minWords = i.min_words;
          return voices <= max && count <= maxWords && count >= minWords && i.production_fx === (custom.production_fx ?? false);
        });
        res = first || null;
      }

      return {
        id: item.id,
        type: item.type,
        res,
      };
    },

    getPrices(): APIItemPrices {
      const items: APIItemPrices = [];
      this.items.forEach((item) => {
        items.push(this.getPriceItem(item));
      });
      return items;
    },
    // End Calculation Functions

    // Voices
    async addVoice(voice: VoiceTalent, group: VoiceSelectionGroup, index: number) {
      const current = this.items[index];
      let voices: Array<VoiceTalent>;
      if (group === 'alternative') {
        voices = [...current.alternative_voices, voice];
        await this.updateItem({ ...current, alternative_voices: voices });
      } else {
        voices = [...current.primary_voices, voice];
        await this.updateItem({ ...current, primary_voices: voices });
      }
      await this.updatePrices();
    },

    async removeVoice(voice: VoiceTalent, index: number) {
      const current = this.items[index];
      const primary = current.primary_voices.filter((v) => v.id !== voice.id);
      const alternative = current.alternative_voices.filter((v) => v.id !== voice.id);
      await this.updateItem({ ...current, primary_voices: primary, alternative_voices: alternative });
      await this.updatePrices();
    },

    async commitItems() {
      const { commit } = useStorage();
      await commit('items', this.items);
    },

    async removeCurrentOrder() {
      this.order = undefined;
      const { commit } = useStorage();
      await commit('order', this.order ?? null);
    },

    async commitOrders(order?: CreateOrderResponse) {
      const { commit } = useStorage();
      if (order) {
        const local = this.orders.find((o) => o.id === order.id);
        if (local) {
          this.orders.forEach((value, index) => {
            if (value.id === order.id) this.orders[index] = { ...local, ...order };
          });
        } else {
          this.orders.push(order);
        }
        await commit('order', this.order);
      }
      await commit('orders', this.orders);
    },

    async resetVoices(index: number) {
      this.items[index].primary_voices = [];
      this.items[index].alternative_voices = [];
      const current = this.item(index);
      await this.updateItem({ ...current, primary_voices: [], alternative_voices: [] });
      await this.updatePrices();
    },
    // End Voices

    // Start Audio Creator
    async createOrder() {
      const url = apiUrl('audio-creator/save', 'v1');
      const res = await apiPostRequest(url, this.orderPayload());
      this.order = res.data as CreateOrderResponse;
      await this.commitOrders(this.order);
      // console.log(this.order);
    },

    async init() {
      const pkg = usePackageManager();
      await this.cartSetup();
      await pkg.refresh(this.item(this.index).category_slug, this.item(this.index).option_slug);
      this.initialized = true;
      await this.setParams();
      await this.updatePrices();
    },

    async clear() {
      const { removeData } = useStorage();
      await removeData('items');
      await removeData('order');
      await removeData('index');
    },

    async startNewOrder() {
      this.items = [defaultCartItem];
      this.index = 0;
      this.other = false;

      await this.removeCurrentOrder();

      await this.removeCoupon();
      await this.updatePrices();

      await this.commitOrders();
      await this.commitItems();
      await this.setCurrent(this.index);
    },

    async reset() {
      await this.clear();
      this.order = undefined;
      this.items = [defaultCartItem];
      this.index = 0;
      this.form = defaultCartForm;
      this.other = false;
      this.lfmDepartment = 'LFM Audio';

      // refill storage
      await this.commitOrders();
      await this.commitItems();
      await this.commitForm();

      // Set current
      const { category } = usePackageManager();
      if (category) {
        this.items[this.index].category_slug = category.slug;
        this.items[this.index].option_slug = category.options[0].slug;
        await this.selectOption(category, category.options[0]);
      }
      await this.setCurrent(this.index);
    },
    // End Audio Creator
  },
});

export { useCart, CartState };

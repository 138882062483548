import { defineStore } from 'pinia';
import { useStorage } from '@/stores/storage';

interface UserPreferencesState {
  mode: 'light' | 'dark';
  prefersDark: MediaQueryList;
}

const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

const useUserPreferences = defineStore('user-preferences', {
  state: (): UserPreferencesState => ({
    mode: 'light',
    prefersDark,
  }),
  getters: {
    isDark: (state) => state.mode === 'dark',
  },
  actions: {
    async changeTheme(isDark: boolean) {
      const storage = useStorage();
      const current = isDark ? 'dark' : 'light';
      await storage.addData('color-theme', current);
      document.body.classList.toggle('dark', isDark);
      this.mode = current;
    },

    async getTheme(): Promise<boolean> {
      const { getData } = useStorage();
      let isDark: boolean;
      const current = await getData('color-theme');
      if (current) isDark = current === 'dark';
      else isDark = false;
      // else isDark = this.prefersDark.matches;
      return isDark;
    },

    async init() {
      const isDark = await this.getTheme();
      await this.changeTheme(isDark);
      this.prefersDark.addEventListener('change', (e) => this.changeTheme(e.matches));
    },

    async clear() {
      const { removeData } = useStorage();
      await removeData('color-theme');
    },

    async reset(restoreTheme?: boolean) {
      await this.clear();
      if (restoreTheme) {
        await this.changeTheme(this.isDark);
      } else {
        const isDark = await this.getTheme();
        await this.changeTheme(isDark);
      }
    },
  },
});

export { useUserPreferences, UserPreferencesState };

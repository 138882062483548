import { RouteLocationNormalized } from 'vue-router';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import eventEmitter from 'mitt';
import { inject } from 'vue';

const mitt = () => {
  return inject('emitter', eventEmitter());
};

const getEnv = (key: string, defaultValue?: any) => process.env[key] || defaultValue;

const appTitle = getEnv('VUE_APP_TITLE', 'LFM Audio');

const getPageTitle = (route: RouteLocationNormalized) => {
  let documentTitle = appTitle;
  if (route.params.title) {
    documentTitle = `${documentTitle} - ${route.params.title}`;
  } else if (typeof route.name === 'string') {
    documentTitle = `${documentTitle} - ${route.name}`;
  }
  return documentTitle;
};

const jsonEncode = (data: any) => JSON.stringify(data);

const jsonDecode = (data: string) => JSON.parse(data);

const dateUtil = (date?: string | number | dayjs.Dayjs | Date | null | undefined) => {
  dayjs.extend(relativeTime);
  return dayjs(date);
};

const currentDateToString = () => {
  const date = new Date();
  return date.toDateString();
};

const randomId = () => parseInt((Math.random() * 100000).toFixed(0), 10);

const currentDateTimeToString = (): string => {
  const date = new Date();
  return `${date.toDateString()} ${date.toTimeString()}`;
};

const appVersion: string = getEnv('VUE_APP_VERSION', '');

const unixTimeStamp = (dateString?: string): number => Date.parse(dateString ?? currentDateTimeToString()) / 1000;

export { mitt, getEnv, getPageTitle, jsonEncode, jsonDecode, dateUtil, currentDateToString, appTitle, randomId, currentDateTimeToString, unixTimeStamp, appVersion };

import { defineStore } from 'pinia';
import { PackageCategory, PackageCategoryOption, PackagePayload } from '@/interfaces/package-category';
import { Bundle } from '@/interfaces/cart';
import { OrderAddon } from '@/interfaces/prices';
import { currentDateTimeToString, jsonDecode, jsonEncode } from '@/utils';

import { apiGetRequest, apiPostRequest, apiUrl } from '@/api';
import { useTimer } from '@/stores/timer';
import { useStorage } from '@/stores/storage';

interface PackageManagerState {
  categorySlug: string;
  optionSlug: string;
  payload: PackagePayload;
  last_checked: string;
  categories: Array<PackageCategory>;
  bundles: Array<Bundle>;
  genres: Array<string>;
  coldMixout: OrderAddon | null;
}

const usePackageManager = defineStore('package-manager', {
  state(): PackageManagerState {
    return {
      categorySlug: 'imaging',
      optionSlug: 'imaging',
      payload: {
        last_checked: '',
        categories: [],
        bundles: [],
        genres: [],
        category: null,
        option: null,
        coldMixout: null,
      },
      last_checked: '',
      categories: [],
      bundles: [],
      genres: [],
      coldMixout: null,
    };
  },
  getters: {
    category: (state: PackageManagerState) => state.categories.find((c) => c.slug === state.categorySlug) ?? null,
    option(state: PackageManagerState) {
      const cat = state.categories.find((c) => c.slug === state.categorySlug);
      return cat?.options.find((opt) => opt.slug === state.optionSlug) ?? null;
    },
    itemCategory: (state: PackageManagerState) => {
      return (slug: string) => state.categories.find((c) => c.slug === slug) ?? null;
    },
    itemOption: (state: PackageManagerState) => {
      return (slug: string, catSlug: string) => {
        const category = state.categories.find((c) => c.slug === catSlug) ?? null;
        if (category) return category.options.find((opt) => opt.slug === slug) ?? null;
        return null;
      };
    },
    groupBundles: (state: PackageManagerState) => {
      return (group: string, categorySlug: string) => state.bundles.filter((item) => item.type === group && (item.categories ?? []).includes(categorySlug));
    },
    getCategoryById: (state: PackageManagerState) => {
      return (id: string | number) => state.payload.categories.find((c) => c.id === id);
    },
    getCategoryBySlug: (state: PackageManagerState) => {
      return (slug: string) => state.payload.categories.find((c) => c.slug === slug);
    },
  },
  actions: {
    async commitPayload() {
      const storage = useStorage();
      const newPayload: PackagePayload = {
        categories: this.categories,
        bundles: this.bundles,
        genres: this.genres,
        category: this.category,
        option: this.option,
        last_checked: this.last_checked,
        coldMixout: this.coldMixout,
      };
      const encode = jsonEncode(newPayload);
      const toSave = jsonDecode(encode);
      this.payload = newPayload;
      await storage.addData('pkg_payload', toSave);
    },

    // API Calls
    async getCategories(setLast?: boolean) {
      const timer = useTimer();
      if (!timer.reloadPkg) {
        const data = await this.getPayload();
        this.categories = data.categories;
      } else {
        const url = apiUrl('orders/categories/all', 'v1');
        const res = await apiGetRequest(url);
        this.categories = res.data as Array<PackageCategory>;
        if (setLast) await this.setLastFetched();
      }
      await this.commitPayload();
    },

    async getBundles(setLast?: boolean) {
      const data = await this.getPayload();
      const timer = useTimer();
      if (data.bundles.length > 0 && !timer.reloadPkg) {
        this.bundles = data.bundles;
      } else {
        const url = apiUrl('orders/bundles/all', 'v1');
        const res = await apiGetRequest(url);
        this.bundles = res.data as Array<Bundle>;
        if (setLast) await this.setLastFetched();
      }
      await this.commitPayload();
    },

    // eslint-disable-next-line class-methods-use-this
    async getColdMixout(): Promise<OrderAddon> {
      const url = apiUrl('orders/get-price', 'v1');
      const response = await apiPostRequest(url, {
        price_group: 'addons',
        type: 'cold_mixout',
      });
      return response.data as OrderAddon;
    },

    async getAllPackages() {
      const payload = await this.getPayload();
      const timer = useTimer();
      const refresh = timer.reloadPkg || !payload.bundles.length || !payload.categories.length;
      if (refresh) {
        const url = apiUrl('audio-creator/data/all', 'v1');
        const res = await apiGetRequest(url);

        // Set Data
        const categories = res.data.categories as Array<PackageCategory>;
        const bundles = res.data.bundles as Array<Bundle>;
        const genres = res.data.genres as Array<string>;

        // Get Mixout
        const coldMixout = await this.getColdMixout();

        this.setValues({ ...this.payload, categories, bundles, genres, coldMixout });
        await this.setLastFetched();
      } else {
        // Set from payload
        this.payload = payload;
        const { bundles, categories, coldMixout } = payload;
        this.setValues({ ...this.payload, categories, bundles, coldMixout });
      }
    },

    setValues(payload: PackagePayload) {
      const timer = useTimer();
      this.payload = payload;
      this.categories = payload.categories;
      this.bundles = payload.bundles;
      this.genres = payload.genres;
      this.last_checked = payload.last_checked;
      timer.pkgChecked = this.last_checked;
      this.coldMixout = payload.coldMixout;
    },
    // End API Calls

    // Local Calls
    async setLastFetched() {
      const timer = useTimer();
      const storage = useStorage();
      this.last_checked = currentDateTimeToString();
      timer.pkgChecked = this.last_checked;
      await storage.addData('pkg_fetched', this.last_checked);
    },

    async checkFetched() {
      const timer = useTimer();
      const storage = useStorage();
      this.payload.last_checked = await storage.getData('pkg_fetched');
      timer.pkgChecked = this.payload.last_checked;
      return timer.reloadPkg;
    },

    async getPayload(): Promise<PackagePayload> {
      const storage = useStorage();
      const payload = await storage.getData('pkg_payload');
      return (payload ?? this.payload) as PackagePayload;
    },

    async refresh(catSlug: string, optSlug: string) {
      this.categorySlug = catSlug || '';
      this.optionSlug = optSlug || '';
      await this.getAllPackages();
      await this.commitPayload();
    },

    getCategory(categorySlug: string): PackageCategory | undefined {
      return this.payload.categories.find((c) => c.slug === categorySlug);
    },

    getOption(categorySlug: string, optionSlug: string): PackageCategoryOption | undefined {
      const cat = this.getCategory(categorySlug);
      let opt: PackageCategoryOption | undefined;
      if (cat) {
        opt = cat.options.find((o) => o.slug === optionSlug);
      }
      return opt;
    },

    async setCategoryAndOption(categorySlug: string, optionSlug: string) {
      this.categorySlug = categorySlug;
      this.optionSlug = optionSlug;
      await this.commitPayload();
    },
    // End Local Calls

    async init() {
      const data = await this.getPayload();
      await this.getAllPackages();
      await this.getColdMixout();
      this.setValues(data);
      await this.commitPayload();
    },

    async clear() {
      const storage = useStorage();
      await storage.removeData('pkg_fetched');
      await storage.removeData('pkg_payload');
    },

    async reset() {
      await this.clear();
      await this.init();
    },
  },
});

export { usePackageManager, PackageManagerState };

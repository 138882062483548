import { defineStore } from 'pinia';
import { MenuItem, NavItem } from '@/interfaces/nav';
import { apiGetRequest, wpUrl } from '@/api';
import { useCart } from '@/stores/cart';
import { usePackageManager } from '@/stores/package-manager';
import { remove } from '@/utils/filters';
import router from '@/router';
import { UseIonRouterResult } from '@ionic/vue';
import { RouteLocationNormalizedLoaded } from 'vue-router';

interface NavigationState {
  primaryMenu: Array<MenuItem>;
  nav: NavItem;
  current: number;
}

const useNavigation = defineStore('navigation', {
  state: (): NavigationState => {
    return {
      primaryMenu: [],
      nav: {
        prev: null,
        next: null,
        key: 'step1',
      },
      current: 1,
    };
  },
  getters: {
    ready() {
      return (route: RouteLocationNormalizedLoaded) => {
        const cart = useCart();
        const check = cart.check(cart.index);
        const pkg = usePackageManager();
        const { isBundle } = cart;
        const platforms = pkg.option?.platforms ?? [];

        const stepOneReady = check.hasPackage;

        const stepOneBReady = check.hasPackage;

        const stepTwoReady = (stepOneReady && check.hasVoices) || platforms.length === 0;

        const stepThreeBReady = check.filledForm;

        const stepThreeReady = check.filledForm;

        const stepFourReady = stepThreeReady || stepThreeBReady || true;

        const stepFiveReady = stepFourReady;
        const routeName = (route.name ?? 'Step1').toString().toLowerCase();
        let res = false;
        if (routeName === 'step1') res = stepOneReady;
        if (routeName === 'step1b') res = stepOneBReady;
        if (routeName === 'step2') res = stepTwoReady;
        if (routeName === 'step3') res = isBundle(cart.index) ? stepThreeBReady : stepThreeReady;
        if (routeName === 'step4') res = stepFourReady;
        if (routeName === 'step5') res = stepFiveReady;
        return res;
      };
    },
    currentNavItem() {
      return (route: RouteLocationNormalizedLoaded) => {
        const routeName = (route.name ?? 'Step1').toString().toLowerCase();
        const cart = useCart();
        const item = cart.items[cart.index];
        const isDropOrImaging = (item.category_slug === 'imaging' || item.category_slug === 'dj-drops') && item.option_slug !== 'dj-intro';

        const pkg = usePackageManager();
        const option = pkg.getOption(item.category_slug, item.option_slug);
        const platforms = option?.platforms ?? [];

        const step1Next = () => {
          if (isDropOrImaging) return 'Step1b';
          if (platforms.length > 0) return 'Step2';
          return 'Step3';
        };

        const step3Prev = () => {
          if (platforms.length > 0) {
            return 'Step2';
          }
          if (isDropOrImaging) return 'Step1b';
          return 'Step1';
        };

        const items = [
          {
            key: 'step1',
            prev: null,
            next: step1Next(),
          },
          {
            key: 'step1b',
            prev: 'Step1',
            next: 'Step2',
          },
          {
            key: 'step2',
            prev: isDropOrImaging ? 'Step1b' : 'Step1',
            next: 'Step3',
          },
          {
            key: 'step3',
            prev: step3Prev(),
            next: 'Step4',
          },
          {
            key: 'step4',
            prev: 'Step3',
            next: 'Step5',
          },
          {
            key: 'step5',
            prev: 'Step4',
            next: null,
          },
        ];
        return items.find((i) => i.key === routeName) || items[0];
      };
    },
  },
  actions: {
    async getPrimaryMenu() {
      const url = wpUrl('nav/primary', 2);
      // const url = apiUrl('orders/nav/primary', 'v1');
      const items = await apiGetRequest(url);
      const data = items.data as Array<MenuItem>;
      const parents = data.map((i) => ({ ...i, children: data.filter((d) => d.menu_item_parent === i.ID.toString()) }));
      this.primaryMenu = parents.filter((i) => i.menu_item_parent === '0' && i.post_name !== 'create-audio');
    },

    getNav() {
      const route = router.currentRoute.value;
      const cart = useCart();
      const item = cart.items[cart.index];
      const pkg = usePackageManager();
      const routeName = (route.name ?? 'Step1').toString().toLowerCase();
      const catSlug = item.category_slug ?? '';
      const optSlug = item.option_slug ?? '';
      const option = pkg.getOption(catSlug, optSlug);
      const platforms = option?.platforms ?? [];
      const isDropOrImaging = (catSlug === 'imaging' || catSlug === 'dj-drops') && optSlug !== 'dj-intro';

      const step1Next = () => {
        if (isDropOrImaging) return 'Step1b';
        if (platforms.length > 0) return 'Step2';
        return 'Step3';
      };

      const step3Prev = () => {
        if (platforms.length > 0) {
          return 'Step2';
        }
        if (isDropOrImaging) return 'Step1b';
        return 'Step1';
      };

      const items = [
        {
          key: 'step1',
          prev: null,
          next: step1Next(),
        },
        {
          key: 'step1b',
          prev: 'Step1',
          next: 'Step2',
        },
        {
          key: 'step2',
          prev: isDropOrImaging ? 'Step1b' : 'Step1',
          next: 'Step3',
        },
        {
          key: 'step3',
          prev: step3Prev(),
          next: 'Step4',
        },
        {
          key: 'step4',
          prev: 'Step3',
          next: 'Step5',
        },
        {
          key: 'step5',
          prev: 'Step4',
          next: null,
        },
      ];
      this.nav = items.find((i) => i.key === routeName) || items[0];
    },

    setCurrent(step?: string | null) {
      const route = router.currentRoute.value;
      const routeName = step ?? (route.name as string) ?? '';
      let val = remove(routeName.toLowerCase(), 'step');
      if (val === '1b') val = '1';
      this.current = parseInt(val, 10);
    },

    refresh(step?: string | null) {
      this.getNav();
      this.setCurrent(step);
    },

    async goTo(useRouter: UseIonRouterResult, name: string, replace?: boolean) {
      const cart = useCart();
      if (replace) {
        await useRouter.replace({ name });
      } else {
        await useRouter.push({ name });
      }
      await cart.updatePrices();
      await this.refresh();
    },

    async goBack(useRouter: UseIonRouterResult) {
      if (this.nav.prev) {
        await this.goTo(useRouter, this.nav.prev, true);
      }
    },

    async goToNext(useRouter: UseIonRouterResult) {
      if (this.nav.next) {
        await this.goTo(useRouter, this.nav.next, false);
      }
    },

    async init() {
      if (!this.primaryMenu.length) {
        await this.getPrimaryMenu();
      }
      this.getNav();
      this.setCurrent();
    },
  },
});

export { useNavigation, NavigationState };

import { unixTimeStamp } from '@/utils';
import { defineStore } from 'pinia';
import { useStorage } from '@/stores/storage';

interface TimerState {
  unixTime: number;
  pkgChecked: string;
  geoChecked: string;
}

const useTimer = defineStore('timer', {
  state(): TimerState {
    return {
      unixTime: unixTimeStamp(),
      pkgChecked: '',
      geoChecked: '',
    };
  },
  getters: {
    reloadPkg: (state: TimerState) => state.unixTime - unixTimeStamp(state.pkgChecked) > 300,
    reloadGeo: (state: TimerState) => state.unixTime - unixTimeStamp(state.geoChecked) > 300,
  },
  actions: {
    async getTimes() {
      const storage = useStorage();
      this.geoChecked = (await storage.getData('geo_checked')) ?? '';
      this.pkgChecked = (await storage.getData('pkg_fetched')) ?? '';
    },

    async init() {
      await this.getTimes();
      setInterval(() => {
        this.unixTime = unixTimeStamp();
      }, 1000);
    },
  },
});

export { useTimer, TimerState };

import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import AlacartePage from '@/views/AlacartePage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/steps/1',
  },
  {
    path: '/steps/',
    component: AlacartePage,
    children: [
      {
        path: '',
        redirect: '/steps/1',
        beforeEnter: async (to, from, next) => {
          next();
        },
      },
      {
        path: '1',
        component: () => import('@/views/step1/Step1Page.vue'),
        name: 'Step1',
      },
      {
        path: '1b',
        component: () => import('@/views/step1/Step1bPage.vue'),
        name: 'Step1b',
      },
      {
        path: '2',
        component: () => import('@/views/step2/Step2Page.vue'),
        name: 'Step2',
      },
      {
        path: '3',
        component: () => import('@/views/step3/Step3Page.vue'),
        name: 'Step3',
      },
      {
        path: '4',
        component: () => import('@/views/step4/Step4Page.vue'),
        name: 'Step4',
      },
      {
        path: '5',
        component: () => import('@/views/step5/Step5Page.vue'),
        name: 'Step5',
      },
      {
        path: 'thank-you',
        component: () => import('@/views/step6/ThankYou.vue'),
        name: 'ThankYou',
      },
    ],
    beforeEnter: async (to, from, next) => {
      // await navigation.init();
      // navigation.stepGuard(to, next);
      next();
    },
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/steps/1',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

import { defineStore } from 'pinia';
import { PaymentIntent } from '@stripe/stripe-js';
import { OrderResponseBody, PurchaseUnit } from '@paypal/paypal-js';
import { useCart } from '@/stores/cart';

import { apiPostRequest, apiUrl } from '@/api';
import { CreateOrderResponse, PaymentMethod } from '@/interfaces/cart';
import { useStorage } from '@/stores/storage';
import { loadingController, UseIonRouterResult } from '@ionic/vue';
import { useNavigation } from '@/stores/navigation';

interface PaymentManagerState {
  initialized: boolean;
}

interface PaymentPayload {
  order_id: number | string;
  payment_method: PaymentMethod;
  payment_id: string;
  currency: string;
  lfm_department: string;
  trigger_field: string;
}

const usePaymentManager = defineStore('payment', {
  state: (): PaymentManagerState => {
    return {
      initialized: false,
    };
  },
  getters: {
    orderPayment() {
      return (orderId?: number | string) => {
        const cart = useCart();
        const order = cart.orders.find((o) => o.id === orderId);
        return order?.payment ?? null;
      };
    },
    paymentCompleted() {
      return (orderId?: number | string) => {
        const payment = this.orderPayment(orderId);
        return payment?.status === 'succeeded' || payment?.status === 'COMPLETED';
      };
    },
    mustPay() {
      return (orderId?: number | string) => {
        const cart = useCart();
        const order = cart.getOrderById(orderId);
        if (order) {
          return order.payment?.status !== 'COMPLETED' && order.payment?.status !== 'succeeded' && order.status.toLowerCase() !== 'paid';
        }
        return false;
      };
    },
    mustRefreshOrder() {
      return (orderId?: number | string) => {
        const cart = useCart();
        const order = cart.getOrderById(orderId);
        if (order) {
          return order.status.toLowerCase() !== 'paid' && this.paymentCompleted;
        }
        return false;
      };
    },
    paymentMethod(): 'paypal' | 'stripe' {
      const cart = useCart();
      return cart.form.payment_method;
    },
    paymentId() {
      return () => {
        const cart = useCart();
        return cart.order?.payment?.id;
      };
    },
    paypalPaymentItems: (): Array<PurchaseUnit> => {
      const { order } = useCart();
      const currency = `${order?.currency.toUpperCase()}`;
      return [
        {
          amount: {
            breakdown: {
              item_total: {
                value: `${order?.total}`,
                currency_code: currency,
              },
            },
            value: `${order?.total}`,
            currency_code: currency,
          },
          items: [
            {
              name: `Audio Creator - ${order?.company_name} - ${order?.skus}`,
              quantity: '1',
              unit_amount: {
                value: `${order?.total}`,
                currency_code: `${order?.currency}`,
              },
              category: 'DIGITAL_GOODS',
            },
          ],
          // reference_id: 'REF',
          description: `${order?.order_comment}`,
          // custom_id: `${order?.id ?? ''}`,
          // invoice_id: `${order?.invoice_id}`,
        },
      ];
    },
    stripePaymentItems: () => {
      const { order } = useCart();
      return {
        amount: order?.total,
        currency: order?.currency,
        customer_name: order?.company_name,
        company_name: order?.company_name,
        email: order?.email,
        description: order?.order_comment,
      };
    },
    paymentPayload(): PaymentPayload {
      const cart = useCart();
      const { order, orderPayload } = cart;
      return {
        order_id: order?.id ?? '',
        payment_method: this.paymentMethod,
        payment_id: this.paymentId() ?? '',
        currency: order?.currency ?? '',
        lfm_department: orderPayload().lfm_department,
        trigger_field: orderPayload().trigger_field,
      };
    },
  },
  actions: {
    async savePayment(payment: PaymentIntent | OrderResponseBody) {
      if (payment.status === 'succeeded' || payment.status === 'COMPLETED') {
        const cart = useCart();
        if (cart.order) {
          cart.order.payment = payment;
          await cart.commitOrders(cart.order);
        }
      }
    },

    async sendPayment() {
      const loading = await loadingController.create({
        message: 'Updating your order...',
      });

      await loading.present();

      const cart = useCart();
      const url = apiUrl('audio-creator/submit-payment', 'v1');
      try {
        const res = await apiPostRequest(url, this.paymentPayload);
        cart.order = res.data as CreateOrderResponse;
        await cart.commitOrders(cart.order);
      } catch (e) {
        //
      }
      await loading.dismiss();
    },

    async submitOrderPayment(router: UseIonRouterResult) {
      await this.sendPayment();
      const { order } = useCart();
      if (order && order.status === 'Paid') {
        // Redirect to thank you page if paid //
        const { goTo } = useNavigation();
        await goTo(router, 'ThankYou', true);
      }
    },

    async refreshOrderPayment(orderId?: number | string) {
      if (this.mustRefreshOrder(orderId)) {
        let gateway: PaymentMethod | undefined;

        if (this.orderPayment(orderId)?.status === 'COMPLETED') {
          gateway = 'paypal';
        }
        if (this.orderPayment(orderId)?.status === 'succeeded') {
          gateway = 'stripe';
        }

        if (gateway) {
          await this.sendPayment();
        }
      }
    },

    async testPayment() {
      const payment = {
        id: '0GJ09115FK4145830',
        intent: 'CAPTURE',
        status: 'COMPLETED',
        purchase_units: [
          {
            reference_id: 'default',
            amount: { currency_code: 'USD', value: '1.65', breakdown: { item_total: { currency_code: 'USD', value: '1.65' }, shipping: { currency_code: 'USD', value: '0.00' }, handling: { currency_code: 'USD', value: '0.00' }, insurance: { currency_code: 'USD', value: '0.00' }, shipping_discount: { currency_code: 'USD', value: '0.00' } } },
            payee: { email_address: 'Ibmgeniuz-facilitator@gmail.com', merchant_id: 'KTQ34LTEJ95N8' },
            description: 'Product Type: Imaging\nScript:\n Testing',
            items: [{ name: 'Audio Creator - Nana CRM & Books - LFMA-CVO', unit_amount: { currency_code: 'USD', value: '1.65' }, tax: { currency_code: 'USD', value: '0.00' }, quantity: '1' }],
            shipping: { name: { full_name: 'Nana Test' }, address: { address_line_1: '1 Main St', admin_area_2: 'San Jose', admin_area_1: 'CA', postal_code: '95131', country_code: 'US' } },
            payments: { captures: [{ id: '1XP80822CK425053A', status: 'COMPLETED', amount: { currency_code: 'USD', value: '1.65' }, final_capture: true, seller_protection: { status: 'ELIGIBLE', dispute_categories: ['ITEM_NOT_RECEIVED', 'UNAUTHORIZED_TRANSACTION'] }, create_time: '2023-02-14T01:25:37Z', update_time: '2023-02-14T01:25:37Z' }] },
          },
        ],
        payer: { name: { given_name: 'Nana', surname: 'Test' }, email_address: 'test.buyer@lfmsandbox.com', payer_id: 'QR5LG6SMPCF8Y', address: { country_code: 'US' } },
        create_time: '2023-02-14T01:25:20Z',
        update_time: '2023-02-14T01:25:37Z',
        links: [{ href: 'https://api.sandbox.paypal.com/v2/checkout/orders/0GJ09115FK4145830', rel: 'self', method: 'GET' }],
      };
      const res = payment as unknown as OrderResponseBody;
      // console.log(res.status);
      await this.savePayment(res);
    },

    async init(orderId?: number | string) {
      await this.refreshOrderPayment(orderId);
      this.initialized = true;
    },

    async clear() {
      const storage = useStorage();
      await storage.removeData('order_payment');
      await storage.removeData('payment_intent');
      await storage.removeData('paypal_payment');
    },

    async reset() {
      await this.clear();
    },
  },
});

export { usePaymentManager, PaymentManagerState };

import { defineStore } from 'pinia';
import { ApiCountry, ApiCountryMapped, Location } from '@/interfaces/location';
import { currentDateToString } from '@/utils';

import { apiGetRequest, apiUrl, GEO_API_URL } from '@/api';
import { CurrencyName } from '@/interfaces/currency';
import { useStorage } from '@/stores/storage';

interface LocationState {
  userGeo: Location | null;
  countries: Array<ApiCountryMapped>;
  num: number;
  ip: string;
}

const useLocation = defineStore('location-store', {
  state: (): LocationState => ({
    userGeo: null,
    countries: [],
    num: 0,
    ip: '',
  }),
  getters: {
    nowDate: (): string => currentDateToString(),
    currency(): CurrencyName {
      if (this.userGeo) {
        const { continent, country } = this.userGeo;
        const isoCode = country.iso_code.toLowerCase();
        const continentCode = continent.code.toLowerCase();
        if (isoCode === 'gb') return 'gbp';
        if (continentCode === 'eu' && isoCode !== 'ua') return 'eur';
        if (isoCode === 'nz') return 'nzd';
        if (isoCode === 'au') return 'aud';
        if (isoCode === 'ae') return 'usd_uae';
      }
      return 'usd';
    },
    defaultAccent() {
      const currencyName = this.currency;
      if (currencyName === 'nzd') return 'New Zealand';
      if (currencyName === 'aud') return 'Australian';
      if (currencyName === 'gbp') return 'British';
      if (currencyName === 'usd') return 'American';
      return '';
    },
  },
  actions: {
    async getIp() {
      const url = apiUrl('ip', 'v1');
      const res = await apiGetRequest(url);
      this.ip = res.data;
      return this.ip;
    },
    async geoChecked(location: Location) {
      return this.ip === location.ip;
    },
    currentLocation(): Promise<Location | null> {
      return new Promise((resolve, reject) => {
        fetch(GEO_API_URL, {
          method: 'GET',
        })
          .then((response) => response.json())
          .then(async (result) => {
            resolve(result);
          })
          .catch((error) => reject(error));
      });
    },
    getCountries(): Promise<Array<ApiCountry>> {
      const url = apiUrl('orders/countries', 'v1');
      return new Promise((resolve, reject) => {
        apiGetRequest(url, {
          params: {
            sort_by: 'phone_code',
          },
        })
          .then((response) => {
            resolve(response.data.data as Array<ApiCountry>);
          })
          .catch((error) => reject(error));
      });
    },
    async setCountries() {
      const { commit, getData } = useStorage();
      const localCountries = await getData('countries');
      if (localCountries) this.countries = localCountries as Array<ApiCountryMapped>;
      else {
        this.countries = await this.getCountries();
        if (this.countries.length) {
          this.countries = this.countries
            .filter((c) => c.phone_code.trim().length)
            .map((c) => ({
              name: c.name,
              code: c.code,
              phone_code: c.phone_code,
              emoji: c.emoji,
              currency: c.currency,
              continent_code: c.continent_code,
            }));
          await commit('countries', this.countries);
        }
      }
    },
    async setLocation() {
      const { getData, commit } = useStorage();
      const local = await getData('location');
      if (local) {
        const exists = await this.geoChecked(local);
        if (exists) this.userGeo = local as Location;
        else this.userGeo = await this.currentLocation();
      } else {
        this.userGeo = await this.currentLocation();
      }
      if (this.userGeo) {
        await commit('location', this.userGeo);
        await commit('geo_checked', this.nowDate);
      }
    },
    async init() {
      await this.getIp();
      await this.setCountries();
      await this.setLocation();
    },

    async clear() {
      const storage = useStorage();
      await storage.removeData('location');
      await storage.removeData('geo_checked');
    },

    async reset() {
      await this.clear();
      await this.init();
    },
  },
});

export { useLocation, LocationState };

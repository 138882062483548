import mitt from 'mitt';
import { usePackageManager } from '@/stores/package-manager';
import { useTimer } from '@/stores/timer';
import { useLocation } from '@/stores/location';
import { useCart } from '@/stores/cart';
import { useNavigation } from '@/stores/navigation';
import { useUserPreferences } from '@/stores/user-preferences';

// import { useAudioRecorder } from '@/stores/audio-recorder';
import { loadingController, UseIonRouterResult } from '@ionic/vue';
import { usePaymentManager } from '@/stores/payment-manager';
import { useStorage } from '@/stores/storage';
import { appVersion } from '@/utils';
import { ref } from 'vue';

export default () => {
  const emitter = mitt();

  const timer = useTimer();
  const location = useLocation();
  const pkg = usePackageManager();
  const cart = useCart();
  const pm = usePaymentManager();
  const navigation = useNavigation();
  const pref = useUserPreferences();
  // const recorder = useAudioRecorder();
  const storage = useStorage();

  const showBeta = ref<'yes' | 'no'>('yes');

  const version = ref<string>();

  const getAppVersion = async () => {
    version.value = (await storage.getData('app_version')) ?? appVersion;
  };

  const setAppVersion = async () => {
    await storage.addData('app_version', appVersion);
  };

  const getBetaStatus = async () => {
    showBeta.value = (await storage.getData('show_beta')) ?? 'yes';
    return showBeta.value;
  };

  const setBetaStatus = async (show: 'yes' | 'no') => {
    await storage.addData('show_beta', show);
    showBeta.value = show;
  };

  const init = async (showLoader?: boolean) => {
    const loader = await loadingController.create({
      message: 'Loading Audio Creator...',
    });
    if (showLoader) {
      await loader.present();
    }

    await storage.init();
    // await storage.addData('test', 'testing it');
    await timer.init();
    await location.init();
    await pkg.init();
    await cart.init();
    await navigation.init();
    await pref.init();
    await pm.init();

    await loader.dismiss();
  };

  // const clear = async (showLoader?: boolean) => {
  //   // Keep Some Data
  //   const category = pkg.categories[0];
  //   const option = category.options[0];
  //   const { isDark } = pref;
  //
  //   // Clear Data
  //   await recorder.clear();
  //   await pm.clear();
  //   await cart.clear();
  //   await pkg.clear();
  //   await location.clear();
  //   // await storage.clear();
  //
  //   // Reset Data
  //   await init(showLoader);
  //
  //   // Return Some Default Data
  //   await pref.changeTheme(isDark);
  //   await cart.selectOption(category, option);
  // };

  const reset = async (router: UseIonRouterResult, message = 'Clearing app data...') => {
    const loader = await loadingController.create({
      message,
    });
    await loader.present();

    await storage.reset();
    await location.reset();
    await pkg.reset();
    await cart.reset();
    await pref.reset();
    await pm.reset();

    await storage.addData('app_version', appVersion);

    // dismiss loader
    await loader.dismiss();

    // Go to First Page
    await navigation.goTo(router, 'Step1', true);
    navigation.refresh();
  };

  const refreshAppVersion = async (router: UseIonRouterResult) => {
    await getAppVersion();
    if (version.value !== appVersion) {
      const loader = await loadingController.create({
        message: 'App updated. Loading new version...',
      });
      loader.duration = 5000;
      await loader.present();

      await storage.reset();
      await location.reset();
      await pkg.reset();
      await cart.reset();
      await pref.reset();
      await pm.reset();

      await setAppVersion();
      await setBetaStatus('yes');

      // dismiss loader
      await loader.dismiss();

      // Go to First Page
      await navigation.goTo(router, 'Step1', true);
      navigation.refresh();
    }
  };

  return { emitter, init, reset, refreshAppVersion, getBetaStatus, setBetaStatus, showBeta };
};
